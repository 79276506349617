import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Analytics = Loader(
  lazy(() => import('src/content/dashboards/Analytics'))
);
const AnalyzeDashboard = Loader(
  lazy(() => import('src/content/dashboards/Analyze'))
);
const ReviewsDashboard = Loader(
  lazy(() => import('src/content/dashboards/ReviewAnalytics'))
);

const dashboardsRoutes = [
  {
    path: '',
    element: <AnalyzeDashboard />
  },
  {
    path: 'analytics',
    element: <AnalyzeDashboard />
  },
  {
    path: 'ReviewAnalytics',
    element: <ReviewsDashboard />
  }
];

export default dashboardsRoutes;
