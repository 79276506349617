import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Applications

const Mailbox = Loader(lazy(() => import('src/content/applications/Mailbox')));
const Gmail = Loader(lazy(() => import('src/content/applications/Gmail')));
const AmazonReviews = Loader(
  lazy(() => import('src/content/applications/AmazonReviews'))
);
const TrustpilotReviews = Loader(
  lazy(() => import('src/content/applications/TrustpilotReviews'))
);
const EbayReviews = Loader(
  lazy(() => import('src/content/applications/EbayReviews'))
);
const GoogleReviews = Loader(
  lazy(() => import('src/content/applications/GoogleReviews'))
);

const Complaints = Loader(
  lazy(() => import('src/content/dashboards/AnalyzeList'))
);

const applicationsRoutes = [
  {
    path: '',
    element: <Navigate to="mailbox" replace />
  },
  {
    path: 'Complaints',
    element: <Complaints />
  },
  {
    path: 'mailbox',
    children: [
      {
        path: '',
        element: <Navigate to="inbox" replace />
      },
      {
        path: 'tag/:labelTag',
        element: <Mailbox />
      },
      {
        path: 'tag/:labelTag/:mailboxCategory',
        element: <Mailbox />
      },
      {
        path: ':categoryTag',
        element: <Mailbox />
      },
      {
        path: ':categoryTag/:mailboxCategory',
        element: <Mailbox />
      }
    ]
  },
  {
    path: 'Gmail',
    children: [
      {
        path: '',
        element: <Navigate to="inbox" replace />
      },
      {
        path: 'tag/:labelTag',
        element: <Gmail />
      },
      {
        path: 'tag/:labelTag/:mailboxCategory',
        element: <Gmail />
      },
      {
        path: ':categoryTag',
        element: <Gmail />
      },
      {
        path: ':categoryTag/:mailboxCategory',
        element: <Gmail />
      }
    ]
  },
  {
    path: 'reviews',
    children: [
      {
        path: 'amazon',
        element: <AmazonReviews />
      },
      {
        path: 'trustpilot',
        element: <TrustpilotReviews />
      },
      {
        path: 'amazon/:reviewId',
        element: <AmazonReviews />
      },
      {
        path: 'trustpilot/:reviewId',
        element: <TrustpilotReviews />
      },
      {
        path: 'ebay',
        element: <EbayReviews />
      },
      {
        path: 'ebay/:reviewId',
        element: <EbayReviews />
      },
      {
        path: 'googleReview',
        element: <GoogleReviews />
      },
      {
        path: 'googleReview/:reviewId',
        element: <GoogleReviews />
      }
    ]
  }
];

export default applicationsRoutes;
